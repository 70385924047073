// SPDX-FileCopyrightText: 2024 Marco Benzoni
// SPDX-License-Identifier: AGPL-3.0-or-later

import { Tab, TabList, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import config from '../config';
import ApplicationContext from './ApplicationContext';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, usePath } from '../util';

const useStyles = makeStyles({
	nav: {
		maxWidth: '300px',
		width: '100%',
	},
	navOverlaid: {
		position: 'fixed',
		top: '50px',
		left: 0,
		zIndex: 100,
		backgroundColor: tokens.colorNeutralBackground1,
		height: 'calc(100vh - 50px)',
	},
	navOverlaidAnimated: {
		transitionTimingFunction: tokens.curveDecelerateMax,
		transitionProperty: 'transform',
		transitionDuration: '0.2s',
	},
	navNormal: {},
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		zIndex: 10,
		backgroundColor: tokens.colorBackgroundOverlay,
	},
});

const Menu: FunctionComponent = () => {
	const [overlaid, setOverlaid] = useState(false);
	const styles = useStyles();
	const url = usePath();
	const appCtx = useContext(ApplicationContext);
	const { isMenuOpen, setApplicationContext } = appCtx;
	const navigate = useNavigate();

	useEffect(() => {
		setApplicationContext(ctx => ({ ...ctx, isMenuAlwaysVisible: !overlaid }));
	}, [ overlaid ]);

	useEffect(() => {
		const updateOverlayUse = () => {
			setOverlaid(window.innerWidth < 1000);
		}
		window.addEventListener('resize', updateOverlayUse);
		updateOverlayUse();
		return () => {
			window.removeEventListener('resize', updateOverlayUse);
		}
	}, []);

	const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion)');

	return (
		<>
			<nav
				className={mergeClasses(styles.nav, (overlaid ? mergeClasses(styles.navOverlaid, !prefersReducedMotion && styles.navOverlaidAnimated) : styles.navNormal))}
				style={{ transform: overlaid ? ( isMenuOpen ? 'translateX(0%)' : 'translateX(-100%)' ) : undefined }}
			>
				<TabList
					appearance={overlaid ? 'subtle' : 'transparent'}
					size="large"
					onTabSelect={(_, data) => {
						if (typeof data.value !== 'string')
							throw new Error('Invalid tab value');

						const url = new URL(data.value, window.location.href);
						if (url.origin === window.location.origin) {
							navigate(url.href.substring(url.origin.length));
							setApplicationContext(ctx => ({ ...ctx, isMenuOpen: false }));
						} else {
							window.open(url);
						}
					}}
					selectedValue={url}
					vertical
					>
					{config.menu.map((item, i) => {
						if (item.shouldShow && !item.shouldShow({ ...appCtx, isShortcutGenerator: false })) {
							// item has a show filter and that filter decided the item should not be shown
							return null;
						}

						return (
							<Tab key={i} value={item.path}>{item.displayName}</Tab>
						);
					})}
				</TabList>
			</nav>
			{
				overlaid && isMenuOpen ? (
					<div className={styles.overlay} onClick={() => setApplicationContext(ctx => ({ ...ctx, isMenuOpen: false }))} />
				) : null
			}
		</>
	);
};

export default Menu;
