// SPDX-FileCopyrightText: 2024 Marco Benzoni
// SPDX-License-Identifier: AGPL-3.0-or-later

import { makeStyles, mergeClasses } from "@fluentui/react-components";
import { FunctionComponent, ReactNode } from "react";

const useStyles = makeStyles({
	section: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

const CenteredPage: FunctionComponent<{ children: ReactNode, className?: string, containerClassName?: string }> = ({ children, className, containerClassName }) => {
	const styles = useStyles();

	return (
		<section className={mergeClasses(styles.section, className)}>
			<div className={containerClassName}>
				{children}
			</div>
		</section>
	);
}

export default CenteredPage;
