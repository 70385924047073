// SPDX-FileCopyrightText: 2024 Marco Benzoni
// SPDX-License-Identifier: AGPL-3.0-or-later

import React, { Suspense, lazy, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { FluentProvider, Theme, makeStyles } from "@fluentui/react-components";

import { Header } from "./components/Header.js";

import "./style.css";
import { darkTheme, lightTheme } from "./theme.js";
import Loading from "./pages/Loading.js";
import config from "./config.js";
import Menu from "./components/Menu.js";
import ApplicationContext, {
	ApplicationContextValue,
} from "./components/ApplicationContext.js";
import { useMediaQuery } from "./util.js";
import { useRegisterSW } from "virtual:pwa-register/react";

const About = lazy(() => import("./pages/About"));
const Feed = lazy(() => import("./pages/Feed"));
const Install = lazy(() => import("./pages/Install"));
const License = lazy(() => import("./pages/License"));
const LicenseList = lazy(() => import("./pages/LicenseList"));
const NotFound = lazy(() => import("./pages/NotFound"));

const useStyles = makeStyles({
	outerWrapper: {
		display: "flex",
		flexDirection: "column",
		height: "100vh",
	},
	innerWrapper: {
		display: "flex",
		flexDirection: "row",
		flexGrow: 1,
	},
	main: {
		maxHeight: "calc(100vh - 50px)",
		overflowY: "auto",
		flexGrow: 1,
	},
});

export function App() {
	const [theme, setTheme] = useState<Theme>(lightTheme);
	const [applicationContext, setApplicationContext] =
		useState<ApplicationContextValue>({
			isMenuAlwaysVisible: true,
			isMenuOpen: false,
			isStandalone: false,
			triggerInstallPrompt: null,
		});
	const styles = useStyles();

	const prefersDark = useMediaQuery("(prefers-color-scheme: dark)");
	useEffect(() => {
		setTheme(prefersDark ? darkTheme : lightTheme);
	}, [prefersDark]);

	const isStandalone = useMediaQuery("(display-mode: standalone)");
	useEffect(() => {
		setApplicationContext((ctx) => ({ ...ctx, isStandalone }));
	}, [isStandalone]);

	// ! "beforeinstallprompt" is a non-standard event
	useEffect(() => {
		const onBeforeInstallPrompt = (e: Event) => {
			e.preventDefault();
			setApplicationContext((ctx) => ({
				...ctx,
				triggerInstallPrompt: () => (e as any).prompt(),
			}));
		};
		window.addEventListener("beforeinstallprompt", onBeforeInstallPrompt);
	});

	const {
		offlineReady: [offlineReady, setOfflineReady],
		needRefresh: [needRefresh, setNeedRefresh],
		updateServiceWorker,
	} = useRegisterSW({
		onOfflineReady: () => {
			console.log("Ready for offline use");
		},
		onNeedRefresh: () => {
			console.log("Updating");
			updateServiceWorker();
		},
	});

	return (
		<ApplicationContext.Provider
			value={{ ...applicationContext, setApplicationContext }}
		>
			<FluentProvider theme={theme}>
				<div className={styles.outerWrapper}>
					<Header />
					<div className={styles.innerWrapper}>
						<Menu />
						<main className={styles.main}>
							<Suspense fallback={<Loading />}>
								<Routes>
									<Route
										path="/"
										element={<Navigate to={config.default} replace />}
									/>
									<Route path="/about" element={<About />} />
									<Route path="/feeds/:name" element={<Feed />} />
									<Route path="/install" element={<Install />} />
									<Route path="/licenses" element={<LicenseList />} />
									<Route path="/licenses/:name" element={<License />} />
									<Route path="*" element={<NotFound />} />
								</Routes>
							</Suspense>
						</main>
					</div>
				</div>
			</FluentProvider>
		</ApplicationContext.Provider>
	);
}

createRoot(document.getElementById("a")!).render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
