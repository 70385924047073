// SPDX-FileCopyrightText: 2024 Marco Benzoni
// SPDX-License-Identifier: LicenseRef-FantoniAppAssets

import { BrandVariants, Theme, createDarkTheme, createLightTheme } from "@fluentui/react-components";

const brandVariants: BrandVariants = { 
	10: "#040206",
	20: "#1B1327",
	30: "#281D47",
	40: "#312763",
	50: "#363282",
	60: "#3D3F95",
	70: "#484E9F",
	80: "#545DA8",
	90: "#606CB1",
	100: "#6D7BBA",
	110: "#7B8AC2",
	120: "#8999CB",
	130: "#99A9D3",
	140: "#A9B8DB",
	150: "#BAC7E2",
	160: "#CBD7EA"
};

const lightTheme: Theme = {
	...createLightTheme(brandVariants), 
};

const darkTheme: Theme = {
	...createDarkTheme(brandVariants), 
	colorBrandForeground1: brandVariants[120]
};

darkTheme.colorBrandForeground1 = brandVariants[110];
darkTheme.colorBrandForeground2 = brandVariants[120];

export { brandVariants, lightTheme, darkTheme };
