// SPDX-FileCopyrightText: 2024 Marco Benzoni
// SPDX-License-Identifier: LicenseRef-FantoniAppAssets

import { Config } from '../src/config';

const config: Config = {
	headerTitle: 'Fantoni App',
	default: '/feeds/news',
	menu: [
		{
			displayName: 'Piano delle attività',
			path: 'https://www.istitutofantoni.edu.it/wp/piano-delle-attivita/',
		},
		{
			displayName: 'Eventi/opportunità in provincia',
			path: '/feeds/coge-events',
		},
		{
			displayName: 'Ultime notizie',
			path: '/feeds/news',
		},
		{
			displayName: 'Orario delle lezioni',
			path: 'https://www.istitutofantoni.edu.it/wp/lezioni/',
		},
		{
			displayName: 'Registro',
			path: 'https://web.spaggiari.eu/home/app/default/login.php?custcode=BGII0007',
		},
		{
			displayName: 'Sito',
			path: 'https://www.istitutofantoni.edu.it/wp/',
		},
		{
			displayName: 'Installa quest\'app',
			path: '/install',
			shouldShow: ({ isStandalone }) => !isStandalone,
		},
		{
			displayName: 'Info & privacy',
			path: '/about',
			shouldShow: ({ isShortcutGenerator }) => !isShortcutGenerator,
		}
	],
	feeds: {
		'news': {
			url: new URL('https://www.istitutofantoni.edu.it/wp/wp-json/wp/v2/posts?categories=121'),
			type: 'wp2',
		},
		'coge-events': {
			url: new URL('https://cogefantoni.altervista.org/wp-json/wp/v2/posts?categories=8'),
			type: 'wp2',
		},
	},
}

export default config;
