// SPDX-FileCopyrightText: 2024 Marco Benzoni
// SPDX-License-Identifier: AGPL-3.0-or-later

import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export function usePath() {
	useLocation();
	const url = new URL(window.location.href);
	return url.href.substring(url.origin.length);
}

export function useMediaQuery(query: string, defaultValue: boolean = false): boolean {
	const [matches, setMatches] = useState(defaultValue);
	useEffect(() => {
		if (window.matchMedia) {
			const mediaQuery = window.matchMedia(query);
			const update = () => {
				setMatches(mediaQuery.matches);
			};
			mediaQuery.addEventListener('change', update);
			update();
			return () => {
				mediaQuery.removeEventListener('change', update);
			};
		}
	});
	return matches;
}
