// SPDX-FileCopyrightText: 2024 Marco Benzoni
// SPDX-License-Identifier: AGPL-3.0-or-later

import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { useContext } from 'react';
import { MoreHorizontal32Regular } from '@fluentui/react-icons';
import ApplicationContext from './ApplicationContext';
import config from '../config';

const useStyles = makeStyles({
	header: {
		backgroundColor: tokens.colorBrandBackground,
		color: tokens.colorNeutralForegroundOnBrand,
		height: '50px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		cursor: 'default',
		userSelect: 'none',
		zIndex: 200,
	},
	title: {
		fontSize: tokens.fontSizeBase300,
		marginTop: 0,
		marginBottom: 0,
		paddingLeft: tokens.spacingHorizontalL,
		paddingRight: tokens.spacingHorizontalL,
	},
	menuButton: {
		height: '50px',
		width: '50px',
		color: tokens.colorNeutralForegroundOnBrand,
		backgroundColor: 'rgba(0,0,0,0)',
		cursor: 'pointer',
		...shorthands.border(0),
		...shorthands.padding(0),
		':hover': {
			backgroundColor: tokens.colorBrandBackgroundHover,
		}
	},
});

export function Header() {
	const styles = useStyles();
	const { isMenuAlwaysVisible, isMenuOpen, setApplicationContext } = useContext(ApplicationContext);

	return (
		<header className={styles.header}>
			{isMenuAlwaysVisible ? null : (
				<button className={styles.menuButton} title={isMenuOpen ? 'Chiudi menu' : 'Apri menu'} onClick={() => {
					setApplicationContext(ctx => ({ ...ctx, isMenuOpen: !isMenuOpen }));
				}}><MoreHorizontal32Regular /></button>
			)}
			<h1 className={styles.title}>{config.headerTitle}</h1>
		</header>
	);
}
