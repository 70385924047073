// SPDX-FileCopyrightText: 2024 Marco Benzoni
// SPDX-License-Identifier: AGPL-3.0-or-later

import { Spinner } from "@fluentui/react-components";
import CenteredPage from "../components/CenteredPage";

export default function Loading() {
	return (
		<CenteredPage>
			<Spinner size="huge" />
		</CenteredPage>
	);
}
