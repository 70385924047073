// SPDX-FileCopyrightText: 2024 Marco Benzoni
// SPDX-License-Identifier: AGPL-3.0-or-later

import { Dispatch, SetStateAction, createContext } from "react";

type ApplicationContextValue = {
	isMenuAlwaysVisible: boolean;
	isMenuOpen: boolean;
	isStandalone: boolean;
	triggerInstallPrompt: (() => Promise<{ outcome: 'accepted' | 'dismissed', platform: string }>) | null;
};

type ApplicationContextContent = ApplicationContextValue & {
	setApplicationContext: Dispatch<SetStateAction<ApplicationContextValue>>;
};

const ApplicationContext = createContext<ApplicationContextContent>({
	isMenuAlwaysVisible: true,
	isMenuOpen: false,
	isStandalone: false,
	triggerInstallPrompt: null,
	setApplicationContext: () => { throw new Error('Unsupported') },
});

export default ApplicationContext;
export type { ApplicationContextValue };
